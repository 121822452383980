body {
  margin: 0;
  font-family: Montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
   url("./fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: regular;
 }

 @font-face {
  font-family: "Montserrat-Bold";
  src: local("Montserrat-SemiBold"),
   url("./fonts/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: bold;
 }