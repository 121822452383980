p {
    font-size: 16px;
    line-height: 1.5;
}

h1 {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
}

h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 15px;
}

h3 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

li {
    margin-bottom: 10px;
}

.container {
    margin: 0 auto;
    max-width: 960px;
    padding: 0 1.0875rem 1.45rem;
}